.save {
    position: relative;
    min-height: 100vh;
    height: 100%;
}
.hederDetails{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}
.save .saveHeader {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    
}
.saveHeader .saveH1{
    font-weight: bold;
    font-size: 35px;
}
.saveHeader button {
    padding: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 20px;
}
.save .dataTable{
    display: flex;
    align-items: center;
    justify-content: center;
}
.dataGrid {
    background-color: white;
    padding: 20px;
    max-width: 1024px;
}

.MuiDataGrid-toolbarContainer {
    flex-direction: row-reverse;
    align-items: center;
}

.dataTable img {
    width: 100%;
    height: 99%;
    border-radius: 2%;
    object-fit: cover;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root {
    display: none;
}

.MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd.css-c63i49-MuiInputBase-input-MuiInput-input,
.MuiDataGrid-columnHeaderDraggableContainer,
.MuiDataGrid-cellCheckbox.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor,
.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor.MuiDataGrid-columnHeaderCheckbox {
    display: none;
}

.css-1w53k9d-MuiDataGrid-overlay {
    display: none;
}

.MuiDataGrid-cell.MuiDataGrid-cell--textRight.MuiDataGrid-cell--editable.MuiDataGrid-withBorderColor,
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-withBorderColor,
.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-columnHeader--sorted.MuiDataGrid-withBorderColor,
.MuiDataGrid-columnHeaderDraggableContainer,
.MuiDataGrid-columnHeaderTitleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.w-50{
    width: 50%;
}

/* //loading  */
/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 6px;
    background:
        conic-gradient(from 135deg at top, currentColor 90deg, #0000 0) 0 calc(50% - 4px)/17px 8.5px,
        radial-gradient(farthest-side at bottom left, #0000 calc(100% - 6px), currentColor calc(100% - 5px) 99%, #0000) top right/50% 50% content-box content-box,
        radial-gradient(farthest-side at top, #0000 calc(100% - 6px), currentColor calc(100% - 5px) 99%, #0000) bottom /100% 50% content-box content-box;
    background-repeat: no-repeat;
    animation: l11 1s infinite linear;
}
@keyframes l11 {
    100% {
        transform: rotate(1turn)
    }
}
@media only screen and (max-width:480px) {
    .w-50{
        width: 70%;
    }
}