@import url('https://fonts.googleapis.com/css2?family=Katibeh&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@400;700&family=Katibeh&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.topBar {
    position: fixed;
    top: 0;
    z-index: 20;
    padding: 0px;
    margin: 10px 0px;
    width: 80%;
    border-radius: 25px;
    border: 1px solid #464543;
    background-color: rgba(0, 0, 0, 0.286);
    box-shadow: 1px 0px 11px 3px rgba(255, 184, 1, 0.28);
    -webkit-box-shadow: 1px 0px 11px 3px rgba(255, 183, 1, 0.172);
    -moz-box-shadow: 1px 0px 11px 3px rgba(255, 183, 1, 0.197);
    backdrop-filter: blur(10px);
}

.topBar.scrolled {
    background-color: rgba(37, 37, 37, 0.722);
}

.topBarWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
}

.topBarWrapper .left {
    display: flex;
    align-items: center;
    gap: 10px;
    max-height: 60px;
    margin: 10px 0px;
}

.left img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid gray;
}

.left h6 {
    font-size: 14px;
    font-weight: bold;
    padding-top: 10px;
    letter-spacing: 1.5px;
    margin-bottom: 0;
}

.left p {
    color: #c6c6c6;
    font-size: 14px;
    margin-bottom: 0;
}

.left .icon {
    border: 1px solid #FFB801;
    padding: 5px;
    color: #FFB801 !important;
    border-radius: 50px;
    cursor: pointer;
}

.topBarWrapper .right {
    display: flex;
    align-items: center;
    gap: 30px;
}

.right .langWrapper {
    display: flex;
    gap: 1px;
    align-items: center;
}

.right .langWrapper p {
    font-weight: 300;
    font-size: 18px;
    margin: 0;
    cursor: pointer;
}

.right .langWrapper .icon {
    cursor: pointer;
    z-index: 21;
    position: relative;
}

.right .logout {
    color: rgb(230, 60, 60);
}

.css-11xf66s-MuiTypography-root {
    font-family: Source Sans Pro, sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.6;
    color: white;
    margin: 5px 0 5px 0px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0px -7px 2px -5px rgba(110, 104, 104, 0.21);
    -webkit-box-shadow: 0px -7px 2px -5px rgba(110, 104, 104, 0.21);
    -moz-box-shadow: 0px -7px 2px -5px rgba(110, 104, 104, 0.21);
    padding-top: 5px;
}

.setLang {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    background-color: rgb(21, 43, 79);
    padding: 1px 4px;
    right: 0%;
    top: 60%;
}

.arLang {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    background-color: rgb(21, 43, 79);
    padding: 1px 4px;
    left: 0%;
    top: 60%;
}

.langBtn {
    font-size: 16px;
}

/* // resposive Design */
@media only screen and (max-width:500px) {
    .topBar {
        width: 100%;
        margin-left: 0px;
        position: fixed;
    }

    .topBar.scrolled {
        background-color: rgba(0, 0, 0, 0.691) !important;
    }

    .topBarWrapper .left {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .left img {
        display: none;
    }

    .left p {
        display: none;
    }

    .left h6 {
        padding-left: 12px;
        padding-top: 0;
    }

    .topBarWrapper .right {
        display: flex;
        align-items: center;
        gap: 7px;
    }
}