@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif !important;
}

.app {
  display: flex;
  position: relative;
  font-family: "Katibeh", serif;
  background-image: linear-gradient(to right, #00054F, #130C34);
  height: max-content;
  overflow-x: hidden;
}

.none {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
  color: inherit;
  border-radius: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #301eba42;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(5, 33, 172, 0.455)eba42;
}

/* // display content margin  */
.css-zwiamn {
  margin: 0px !important;
}

/* // for tablet export btn and table border  */
.main-marg {
  margin: 100px 40px;
  position: relative;
}

@media only screen and (max-width:600px) {
  .main-marg {
    margin: 90px 10px;
    position: relative;
  }
}