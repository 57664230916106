/* Add Car  Design  */
.myModal {
    position: absolute;
    padding: 50px;
    border-radius: 10px;
    background-color: #202e4e;
    top: 3%;
    right: 40%;
    box-shadow: rgba(182, 184, 186, 0.35) 0px 50px 100px -20px, rgba(182, 184, 186, 0.35) 0px 30px 60px -30px, rgba(182, 184, 186, 0.35) 0px -2px 6px 0px inset;
}
.modalll {
position: absolute;
    padding: 50px;
    border-radius: 10px;
    background-color: #202e4e;
    top: 2%;
    right: 40%;
    box-shadow: rgba(80, 81, 82, 0.35) 0px 50px 100px -20px, rgba(182, 184, 186, 0.35) 0px 30px 60px -30px, rgba(182, 184, 186, 0.35) 0px -2px 6px 0px inset;
    background-color: rgb(46, 53, 73);
}
.no-pic {
               display: flex;
               align-items: center;
               justify-content: center;
               width: 600px;
               height: 600px;
}
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: 30px;
}
.close:hover {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: tomato;
}
.myModal h1,
.modalll h1 {
    margin-bottom: 40px;
    font-size: 24px;
    color: #ddd;
}
 form {
    display: flex;
    max-width: 500px;
    flex-direction: column;
}
.formItem {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}
.formItem>.label {
    font-size: 14px;
    font-weight: 500;
}
.formItem>input {
    padding: 10px;
    background-color: transparent;
    color: white;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 100%;
}
.addButton {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    transition: linear();
}
.addButton:hover {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: white;
}

/* show img Modal Designs  */
.showHugeImg {
    position: absolute;
    top: 0;
    left: 17%;
    max-height: 500px;
    max-width: 500px;
    background-color: #818a9c;
}
.showHugeImg .saveShowImgLimit {
    max-width: 478px;
    max-height: 430px;
    object-fit: contain;
}

/* delete modal  */
.deleteModal {
    padding: 50px;
    border-radius: 10px;
    background-color: #2a3447;
    position: absolute;
    top: 12px;
    right: 40%;
}
/* // responsive design  */
@media only screen and (max-width:480px) {
    .myModal {
            position: absolute;
            padding: 25px;
            border-radius: 10px;
            background-color: #202e4e;
            top: 4%;
            right: 0;
            left: 0;
        }
                .modalll {
                    padding: 50px;
                    border-radius: 10px;
                    background-color: #2a3447;
                    padding: 50px;
                    border-radius: 10px;
                    position: absolute;
                    background-color: #2a3447;
                    top: 10px;
                    right: 0;
                    left: 0;
                }
                .myModal h1 {
                    margin-bottom: 10px;
                    font-size: 24px;
                    color: #ddd;
                    padding-top: 10px;
                }
       /* // respoinsive design for show img in save and  */
           .showHugeImg {
               position: absolute;
               top: 0;
               left: 0%;
               max-height: 537px;
               max-width: 450px;
               background-color: #818a9c;
            }
        .showHugeImg .saveShowImgLimit {
        max-width: 361px;
        max-height: 425px;
        object-fit: contain;
        }
        .no-pic{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 300px;
                    height: 300px;
                }
                /* delete modal  */
        .deleteModal{
                    padding: 50px;
                    border-radius: 10px;
                    background-color: #2a3447;
                    padding: 50px;
                    border-radius: 10px;
                    position: absolute;
                    background-color: #2a3447;
                    top: 12px;
                    right: 0;
                    left: 0;
        }        
    } 