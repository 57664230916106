.funder{
    position: relative;
    min-height: 100vw;
}
.funderHeader {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.funderHeader button {
    padding: 5px;
    cursor: pointer;
}
.menurContainer {
    width: 250px;
    padding: 5px 20px;
    border: 2px solid var(--soft-bg);
    margin-bottom: 20px;
}
.menur {
    text-transform: uppercase;
    height: calc(100vh - 120px);
}
.storeActionButton {
    padding: 6px;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
}
.storeActionButton:hover {
    cursor: pointer;
    background-color: rgb(34, 34, 34);
    color: white;
}
.myModal {
    position: absolute;
    padding: 25px;
    border-radius: 10px;
    background-color: #202e4e;
    top: 1% !important;
    right: -2%;
}
/* responsive design  */
@media only screen and (max-width:480px) {
    .funder .container{
            display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
    }
}